<template>
  <v-container>
    <v-row class="pa-4">
      <v-col cols="12">
        <s-toolbar
          :color="'#244093'"
          dark
          label="Reporte Morosidad"
          class="tran"
        ></s-toolbar>
        <v-row style="margin: auto">
          <v-card width="100%">
            <v-row style="margin: auto">
              <v-col sm="6" cols="6" lg="4" md="4">
                <s-select-definition
                  :def="1215"
                  v-model="objMounth"
                  return-object
                  label="Seleccione periodo"
                ></s-select-definition>
              </v-col>
              <v-col cols="6" lg="2" md="2" class="mt-3">
                <v-btn width="100%" rounded :color="'info'" small @click="run()"
                  >Buscar</v-btn
                >
              </v-col>
              <v-col cols="6" lg="2" md="2" class="mt-3">
                <v-btn
                  width="100%"
                  rounded
                  :color="'error'"
                  small
                  @click="downloadReport()"
                  :disabled="disabledBtnDownload"
                  >Descargar</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="currentItem">
          <v-tab href="#ReportMoor"> Clientes Morosos</v-tab>
          <v-tab href="#DasboardMoor"> Dashboard</v-tab>
          <v-tab
            v-for="(item, index) in projects"
            :key="index"
            :href="'#' + item.Cuadrante"
            >{{ item.Cuadrante }}</v-tab
          >
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-tabs-items v-model="currentItem">
          <v-tab-item :value="'ReportMoor'">
            <v-row>
              <v-spacer />
              <v-col cols="6">
                <v-text-field
                  v-model="customerSearcher"
                  append-icon="mdi-magnify"
                  label="Cuadrante o Código de venta"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headersCustomers"
                  :items="itemsCustomers"
                  :propsdinamic="{ search: customerSearcher }"
                  :columnsNotNumber="['CodigoVenta']"
                ></gral-table>
              </v-col>
              <v-col cols="12">
                <h2 class="subheading pb-2">Resumen</h2>
                <gral-table
                  :headers="headersCustomersResumen"
                  :items="itemsCustomersResumen"
                ></gral-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            v-for="(item, index) in projects"
            :key="index"
            :value="item.Cuadrante"
          >
            <v-row>
              <v-spacer />
              <v-col cols="12">
                <h2 class="subheading pb-2">Por Numero de Cuota</h2>
                <gral-table
                  :headers="cuotaHeaders"
                  :items="cuotasTables[item.Cuadrante]"
                  :columnsNotNumber="['Porcentaje','PorcentajexContratos']"
                ></gral-table>
              </v-col>
              <v-col cols="12">
                <h2 class="subheading pb-2">Clasificación</h2>
                <gral-table
                  :headers="clasificationHeaders"
                  :items="clasificationTables[item.Cuadrante]"
                ></gral-table>
              </v-col>
              <v-col cols="12">
                <h2 class="subheading pb-2">Resumen</h2>
                <gral-table
                  :headers="resumenMoorheaders"
                  :items="resumenMoorTables[item.Cuadrante]"
                  :columnsNotNumber="['Porcentaje','PorcentajexContratos']"
                ></gral-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'DasboardMoor'">
            <clc-moor-dashboard
              :items="dataDasboard"
              :itemsMora="dataDasboardMora"
              :Month="''"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcMoorDashboard from "./ClcReportDasboard.vue";
import GralTable from "../GralTable.vue";

export default {
  components: {
    GralTable,
    ClcMoorDashboard,
  },
  data() {
    return {
      currentItem: "tab-Funciones",
      items: [],
      itemsCustomers: [],
      itemsCustomersResumen: [],
      headersCustomersResumen: [],
      headers: [],
      resumenMoorheaders: [],
      headersCustomers: [],
      report: {},
      processing: false,
      messageProcessing: "",
      customerSearcher: "",
      dinamicItems: {},
      projects: [],
      dinamicSearcher: {},
      loaded: false,
      cuotasTables: {},
      clasificationTables: {},
      resumenMoorTables: {},
      cuotaHeaders: [],
      clasificationHeaders: [],
      dataDasboard: [],
      dataDasboardMora: [],
      objMounth: null,
      disabledBtnDownload: true,
    };
  },
  methods: {
    loadProjects() {
      this.messageProcessing = "";
      this.report.QryParamsArray = "Month";
      this.report.QryParamsArrayValue = "";
      this.report.QryNameProcedure = "ClcReportProjects_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(this.report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            let tempItems = {},
              tempSearchers = {},
              tempCuotas = {},
              tempClasification = {};
            this.processing = false;
            this.projects = resp.data;

            resp.data.forEach((element) => {
              tempItems[element.Cuadrante] = [];
              tempClasification[element.Cuadrante] = [];
              tempCuotas[element.Cuadrante] = [];
              tempSearchers[element.Cuadrante] = "";
            });
            this.dinamicItems = tempItems;
            this.dinamicSearcher = tempSearchers;
            this.cuotasTables = tempCuotas;
            this.clasificationTables = tempClasification;
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    runViewCustomer() {
      const report = {};

      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportMoorDeliquentClient_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          let tempItems = {};
          if (resp.status == 200) {
            this.processing = false;
            this.itemsCustomers = resp.data;
            this.headersCustomers = Object.keys(resp.data[0]).map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
              filterable:
                item === "CodigoVenta" || item === "Cuadrante" ? true : false,
            }));

            this.projects.forEach((element) => {
              tempItems[element.Cuadrante] = resp.data.filter(
                (item) => item.Cuadrante === element.Cuadrante
              );
            });
            this.dinamicItems = tempItems;
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    runViewDetail() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportMoorByInstallment_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            let tempItems2 = {};
            this.processing = false;

            this.itemsCustomersResumen = resp.data.filter(
              (item) => item.NroCuotas === "Total"
            );
            this.headersCustomersResumen = Object.keys(resp.data[0])
              .filter(
                (item) =>
                  !["NroCuotas", "Porcentaje", "PorcentajexContratos"].includes(
                    item
                  )
              )
              .map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
              }));
            this.cuotaHeaders = Object.keys(resp.data[0])
              .filter((item) => item !== "Cuadrante")
              .map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
              }));
            this.dataDasboard = resp.data
              .filter((item) => item.NroCuotas === "Total")
              .map((item) => ({
                name: item.Cuadrante,
                y: item.NroLotes,
              }));
            this.dataDasboardMora = resp.data
              .filter((item) => item.NroCuotas === "Total")
              .map((item) => ({
                name: item.Cuadrante,
                y: item.Mora,
              }));
            this.projects.forEach((element) => {
              tempItems2[element.Cuadrante] = resp.data.filter(
                (item) => item.Cuadrante === element.Cuadrante
              );
            });
            this.cuotasTables = tempItems2;
          }
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    runViewClasification() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportMoorDetail_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            let tempItems = {};
            this.processing = false;
            this.clasificationHeaders = Object.keys(resp.data[0])
              .filter((item) => item !== "Cuadrante")
              .map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
              }));
            this.projects.forEach((element) => {
              tempItems[element.Cuadrante] = resp.data.filter(
                (item) => item.Cuadrante === element.Cuadrante
              );
            });
            this.clasificationTables = tempItems;
          }
        })

        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    loadMoorResumen() {
      const report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportMoorResumen";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            let tempItems = {};
            this.processing = false;
            this.resumenMoorheaders = Object.keys(resp.data[0])
              .filter((item) => item !== "Cuadrante")
              .map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
              }));
            this.projects.forEach((element) => {
              tempItems[element.Cuadrante] = resp.data.filter(
                (item) => item.Cuadrante === element.Cuadrante
              );
            });
            this.resumenMoorTables = tempItems;
          }
        })

        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    run() {
      this.runViewCustomer();
      this.runViewDetail();
      this.runViewClasification();
      this.loadMoorResumen();
      this.disabledBtnDownload = false;
    },
    downloadReport() {
      this.downloadGral(
        ["Clientes Morosos", "Resumen"],
        [this.itemsCustomers, this.itemsCustomersResumen],
        `Morosidad ${this.objMounth.DedDescription}`
      );
      this.projects.forEach((item) => {
        this.downloadGral(
          ["Por Nro Cuotas", "Clasificación", "Resumen"],
          [
            this.cuotasTables[item.Cuadrante],
            this.clasificationTables[item.Cuadrante],
            this.resumenMoorTables[item.Cuadrante],
          ],
          `Morosidad ${item.Cuadrante} ${this.objMounth.DedDescription}`
        );
      });
    },
    downloadGral(SheetNames, DataTables, nameReport) {
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                nameReport
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
  mounted() {
    this.loadProjects();
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
